<template>
  <div class="viewer">
    <vue-tabs-chrome
      ref="HeaderTab"
      v-model="headerTab.selected"
      :tabs="headerTab.options"
      @input="handleChangeSelectedTab"
      @remove="handleRemoveHeaderTab"
      @dragend="handleDragHeaderTab"
    />
    <iframe
      v-for="tab in iframes"
      :id="tab.key"
      :key="tab.key"
      :src="tab.key"
      v-show="headerTab.selected == tab.key"
      class="page-view"
    />
  </div>
</template>
<script>
import { getFirstNameInnitialFullLastName } from "@/plugins/helper";

export default {
  data() {
    return {
      iframes: [],
    };
  },
  computed: {
    headerTab() {
      return this.$store.getters.headerTab;
    },
  },
  created() {
    let userData = this.$store.getters.userData;
    let { fullName } = userData;
    this.$store.commit("UPDATE_HOME_TAB_NAME", {
      name: getFirstNameInnitialFullLastName(fullName),
    });
  },
  async mounted() {
    this.sockets.common.on("OnOpenScannedQRUrl", this.onOpenScannedQRUrl);
    // seperate iframes and tabs will increase performance drastically
    this.iframes = JSON.parse(JSON.stringify(this.headerTab.options));
    window.addEventListener("message", (e) => {
      if (e.data.event === "ADD_HEADER_TAB") {
        this.handleAddHeaderTab(e.data.data);
      }
      if (e.data.event === "LOGOUT") {
        this.logout();
      }
    });
  },
  methods: {
    onOpenScannedQRUrl(userID, viewUrl) {
      if (userID == this.$store.getters.userData.userID) {
        window.open(viewUrl);
      }
    },
    // when selected tab changed => save into cache
    handleChangeSelectedTab() {
      this.$store.commit("SAVE_HEADER_TAB");
    },
    // when remove any tab => save current config
    handleRemoveHeaderTab({ key }) {
      // find and remove the iframe
      var ind = this.iframes.findIndex((f) => f.key == key);
      this.iframes.splice(ind, 1);
      // save the current tab into cache
      this.$store.commit("SAVE_HEADER_TAB");
    },
    // When tab dragged, update the options into cache
    handleDragHeaderTab() {
      this.$store.commit("SAVE_HEADER_TAB");
    },
    // add header tab
    handleAddHeaderTab({ key, label }) {
      // if this tab already open => open the tab
      if (this.iframes.find((f) => f.key == key)) {
        this.headerTab.selected = key;
        this.$store.commit("SAVE_HEADER_TAB");
        return;
      }
      this.$refs.HeaderTab.addTab({ key, label, closable: true });
      this.headerTab.selected = key;
      this.iframes.push({ key, label });
      this.$store.commit("SAVE_HEADER_TAB");
    },
    logout() {
      this.$store.commit("RESTORE_DEFAULT_HEADER_TAB");
      this.$store.commit("CLEAR_ACCESS_TOKEN");
      this.$store.commit("CLEAR_USER_DATA");
      this.$router.push({ path: "/login" });
    },
  },
};
</script>
<style scoped lang="scss">
.viewer {
  width: 100vw;
  height: 100vh;
  overflow: hidden !important;

  iframe.page-view {
    outline: none;
    border: none;
    width: 100vw;
    height: calc(100vh - 46px);
    display: inline-block;
    overflow: hidden;
  }
}
</style>
<style lang="scss">
.vue-tabs-chrome .tabs-footer {
  height: 2px !important;
}
</style>
